//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change body background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f6f9fc;


$font-family-sans-serif:                    "Roboto Condensed", sans-serif !default;
$navbar-brand-font-size:                    2.7rem !default;
$nav-link-font-size:                				1.275rem !default;
$navbar-nav-link-padding-x:                 2rem !default;

// Grayscale
$white:                       #fff !default;
$gray-100:                    #f6f9fc !default;
$gray-200:                    #edf1f5 !default;
$gray-300:                    #e3e9ef !default;
$gray-400:                    #d7dde2 !default;
$gray-500:                    #b4bbc3 !default;
$gray-600:                    #858c97 !default;
$gray-700:                    #576071 !default;
$gray-800:                    #434a57 !default;
$gray-900:                    #121519 !default;
$black:                       #000 !default;

// Disable Bootstrap's default $colors map
$colors: () !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2 !default;

// Theme colors
$primary:                     #507456 !default;
$secondary:                   $gray-200 !default;
$info:                        #3f7fca !default;
$success:                     #3fca90 !default;
$warning:                     #edcb50 !default;
$danger:                      #ed5050 !default;
$light:                       $white !default;
$dark:                        $gray-900 !default;

